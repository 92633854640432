

.App {
  text-align: center;
}

.item {
  height: 505px;
}

.item img{

  height: 100%;
  width: auto; 
  max-height: 505px;
  top: 0;  
  bottom: 0;  
  left: 0;  
  right: 0;  
  margin: auto;
  position: absolute;  
}
.peopleCarouselImg{
  min-height: 505px;
  margin-top: 51px;
  max-height: 505px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;

}

#google-iframe{
  margin-top: 10px;
  margin-bottom: 20px;
}

.Welcome {
  padding-bottom: 10px;
}

.dotted{
  margin: 0px;
  border-top: dotted 1px;
}

.jumbotron-like {
  padding-left: 10px ;
  padding-right: 10px ;
  margin-bottom: 20px ;
  padding-top: 15px;
  padding-bottom: 15px;
  color: inherit;
  background-color: #d8dce0;
  border-radius: 8px;
  text-align: justify;
    text-justify: inter-word;
    box-shadow: 4px 4px 15px 1px rgba(200, 199, 199, 0.55);
-moz-box-shadow: 4px 4px 15px 1px rgba(200, 199, 199, 0.55);
-webkit-box-shadow: 4px 4px 15px 1px rgba(200, 199, 199, 0.55);
white-space: pre-wrap;
}

.navbar-default .navbar-brand {
  color: #ffffff;
  font-size: 23px;
  text-align: center;
  float: none;
  font-family: 'Charmonman', cursive;
}
.navbar-header {
  float: left;
  padding: 15px;
  text-align: center;
  width: 100%;
}
.navbar {
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  background-color: #71a4f7;
   border: none;
  /* the rest of your styling */
}


blockquote {
  margin: 0 auto;
  padding: 1em;
  border-left: 5px solid #999;
  text-align: left;
  margin-bottom: 10px;
}
blockquote:before {
  display: none;
}
blockquote:not(:first-of-type) {
  margin-top: .5em;
}
blockquote p {
  color: #555;
  font-size: 12pt;
  line-height: 1.4;
  font-family: 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
}
blockquote footer {
  margin-top: .5em;
  padding: 0;
  color: #777;
  font-size: 12pt;
  text-align: left;
  font-style: italic;
}
blockquote footer:before {
  content: '— ';
}
blockquote:nth-of-type(even) {
  text-align: right;
  border-left: none;
  border-right: 5px solid #999;
}
blockquote:nth-of-type(even) footer {
  text-align: right;
}
blockquote:nth-of-type(even) footer:before {
  content: '';
}
blockquote:nth-of-type(even) footer:after {
  content: ' —';
}
@element 'blockquote' and (min-width: 300px) {
  blockquote {
    padding: 1em 20% 1em 1em;
  }
  blockquote p {
    font-size: 14pt;
  }
  blockquote:nth-of-type(even) {
    padding: 1em 1em 1em 20%;
  }
}